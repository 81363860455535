/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";

body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: 'Holcim-Regular';
}

.p-breadcrumb {
    border-radius: 0px !important;
    border-color: #ADADAD !important;
}

.margin-left-15 {
    margin-left: 15px;
}

.d-inline-grid {
    display: inline-grid;
}

.p-link {
    background: none !important;
    border: none !important;
}

.items-center{
    align-items: center !important;
}
.flex-col {
    flex-direction: column !important;
}