@font-face {
  font-family: "Holcim-Regular";
  src: url(../fonts/holcim/Holcim-Regular.ttf);
}
@font-face {
  font-family: "Holcim-Bold";
  src: url(../fonts/holcim/Holcim-Bold.ttf);
}
@font-face {
  font-family: "Holcim-HeadlineLight";
  src: url(../fonts/holcim/Holcim-HeadlineLight.ttf);
}
@font-face {
  font-family: "Holcim-HeadlineBlack";
  src: url(../fonts/holcim/Holcim-HeadlineBlack.ttf);
}
@font-face {
  font-family: "Holcim-Italic";
  src: url(../fonts/holcim/Holcim-Italic.ttf);
}
@font-face {
  font-family: "NotoSans-Regular";
  src: url(../fonts/notosans/NotoSans-Regular.ttf);
}
